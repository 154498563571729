import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import chords from '../utils/Transpose';
import { Paper, Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { kebabCase } from 'lodash';
import { FontSize } from '../components/FontSize';

const useStyles = makeStyles(theme => ({
  songContent: {
    fontFamily:
      'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    fontWeight: 600,
    lineHeight: 1.43,
    'white-space': 'pre-wrap',
  },
  paper: {
    display: 'flex',
    textAlign: 'center',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    boxShadow: 'none',
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    // width: '3vw',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function Song(props) {
  const post = props.data.markdownRemark;

  const siteTitle = props.data.site.siteMetadata.title;
  // const { previous, next } = props.pageContext

  const classes = useStyles();

  const [key, setKey] = React.useState(
    post.frontmatter.key.replace(/\s/g, '').substring(0, 1)
  );
  const [html, setHtml] = React.useState(chords.parse(post.rawMarkdownBody));

  const [scaleBy, setScaleBy] = React.useState(0);

  const [fontSize, setFontSize] = React.useState('14px');

  const changeToGivenKey = (to, type) => {
    const newHtml = chords.shiftScale(
      post.rawMarkdownBody,
      post.frontmatter.key,
      to,
      type
    );
    setHtml(newHtml);
  };

  const scaleUp = () => {
    const upScale = scaleBy + 1;
    setScaleBy(upScale);
    const newHtml = chords.shiftScaleBy(post.rawMarkdownBody, upScale);
    setHtml(newHtml);
  };

  const scaleDown = () => {
    const downScale = scaleBy - 1;
    setScaleBy(downScale);
    const newHtml = chords.shiftScaleBy(post.rawMarkdownBody, downScale);
    setHtml(newHtml);
  };

  const handleChange = (event, newKey) => {
    setKey(newKey);
  };

  const children = [
    <ToggleButton key={1} value="C" onClick={() => changeToGivenKey('C')}>
      <Typography variant="body1">C</Typography>
    </ToggleButton>,
    <ToggleButton key={2} value="C#" onClick={() => changeToGivenKey('C#')}>
      <Typography variant="body1">C♯</Typography>
    </ToggleButton>,
    <ToggleButton
      key={3}
      value="Db"
      onClick={() => changeToGivenKey('Db', 'flat')}
    >
      <Typography variant="body1">D♭</Typography>
    </ToggleButton>,
    <ToggleButton key={4} value="D" onClick={() => changeToGivenKey('D')}>
      <Typography variant="body1">D</Typography>
    </ToggleButton>,
    <ToggleButton key={5} value="D#" onClick={() => changeToGivenKey('D#')}>
      <Typography variant="body1">D♯</Typography>
    </ToggleButton>,
    <ToggleButton
      key={6}
      value="Eb"
      onClick={() => changeToGivenKey('Eb', 'flat')}
    >
      <Typography variant="body1">E♭</Typography>
    </ToggleButton>,
    <ToggleButton key={7} value="E" onClick={() => changeToGivenKey('E')}>
      <Typography variant="body1">E</Typography>
    </ToggleButton>,
    <ToggleButton key={8} value="F" onClick={() => changeToGivenKey('F')}>
      <Typography variant="body1">F</Typography>
    </ToggleButton>,
    <ToggleButton key={9} value="F#" onClick={() => changeToGivenKey('F#')}>
      <Typography variant="body1">F♯</Typography>
    </ToggleButton>,
    <ToggleButton
      key={10}
      value="Gb"
      onClick={() => changeToGivenKey('Gb', 'flat')}
    >
      <Typography variant="body1">G♭</Typography>
    </ToggleButton>,
    <ToggleButton key={11} value="G" onClick={() => changeToGivenKey('G')}>
      <Typography variant="body1">G</Typography>
    </ToggleButton>,
    <ToggleButton key={12} value="G#" onClick={() => changeToGivenKey('G#')}>
      <Typography variant="body1">G♯</Typography>
    </ToggleButton>,
    <ToggleButton
      key={13}
      value="Ab"
      onClick={() => changeToGivenKey('Ab', 'flat')}
    >
      <Typography variant="body1">A♭</Typography>
    </ToggleButton>,
    <ToggleButton key={14} value="A" onClick={() => changeToGivenKey('A')}>
      <Typography variant="body1">A</Typography>
    </ToggleButton>,
    <ToggleButton key={15} value="A#" onClick={() => changeToGivenKey('A#')}>
      <Typography variant="body1">A♯</Typography>
    </ToggleButton>,
    <ToggleButton
      key={16}
      value="Bb"
      onClick={() => changeToGivenKey('Bb', 'flat')}
    >
      <Typography variant="body1">B♭</Typography>
    </ToggleButton>,
    <ToggleButton key={17} value="B" onClick={() => changeToGivenKey('B')}>
      <Typography variant="body1">B</Typography>
    </ToggleButton>,
  ];

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title={`${post.frontmatter.title} by ${post.frontmatter.artist} chords`}
        description={`Guitar chords for ${post.frontmatter.title} by ${post.frontmatter.artist} in chordsL.com`}
      />
      <React.Fragment>
        <Container maxWidth="md">
          <Box my={4}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4" component="h1">
                      {post.frontmatter.title}
                    </Typography>
                    <Typography variant="h6" component="h2">
                      {post.frontmatter.artist}
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <Typography variant="body2" component="h3">
                          {post.frontmatter.beat} | {post.frontmatter.key}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box ml={3}>
                          <Button
                            to={`/chords-by-artist/${kebabCase(
                              post.frontmatter.artist
                            )}`}
                            component={Link}
                            className={classes.link}
                            variant="outlined"
                            size="small"
                          >
                            More
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FontSize setFontSize={setFontSize} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box my={4}>
            <Paper elevation={0} className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Hidden smUp>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={scaleDown}
                      className={classes.button}
                    >
                      scale down
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={scaleUp}
                      className={classes.button}
                    >
                      scale up
                    </Button>
                  </Hidden>
                  <Hidden xsDown>
                    <StyledToggleButtonGroup
                      size="small"
                      value={key}
                      exclusive
                      onChange={handleChange}
                    >
                      {children}
                    </StyledToggleButtonGroup>
                  </Hidden>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Container>
        <Container maxWidth="md">
          <Card>
            <CardContent>
              <pre className={classes.songContent} style={{ fontSize }}>
                <section
                  dangerouslySetInnerHTML={{
                    __html: html,
                  }}
                />
              </pre>
            </CardContent>
          </Card>
        </Container>
      </React.Fragment>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ChordPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      rawMarkdownBody
      frontmatter {
        title
        updated
        artist
        beat
        key
      }
    }
  }
`;
