import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';

export const FontSize = ({ setFontSize }) => {
  const handleFontSize = size => {
    setFontSize(size);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end">
      <Grid item>
        <Button
          style={{ fontSize: '10px', minWidth: '10px', lineHeight: 1 }}
          size="small"
          onClick={() => handleFontSize('8px')}
        >
          A
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{ fontSize: '14px', minWidth: '10px', lineHeight: 1 }}
          size="small"
          onClick={() => handleFontSize('12.25px')}
        >
          A
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{ fontSize: '18px', minWidth: '10px', lineHeight: 1 }}
          size="small"
          onClick={() => handleFontSize('14px')}
        >
          A
        </Button>
      </Grid>
    </Grid>
  );
};
